import React, { useEffect } from 'react';
import './chat.css';
import ChatBot from '../../images/static/botao_posso_ajudar.png';
import 'bootstrap/dist/css/bootstrap.css';

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function Chat() {
  useScript(
    'https://b.smrk.io/sm.js?b=5a4d01494655fdee9a3b3123a5fb47bbe9ab6a55'
  );

  var openBot = (e) => {
    e.preventDefault();
    jic.showManualOverlay('5ae9cf8b208580f2ca37f7b2');
    return false;
  };

  return (
    <footer>
      <div class="hubot-chat open-chat text-right">
        <a
          href="#"
          onClick={openBot}
        >
          <img
            src={ChatBot}
            alt="Hubot"
            class="img-responsive"
            href="#"
          />
        </a>
      </div>
    </footer>
  );
}
