import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 25px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;

    p {
      font-size: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.65px;
    }

    table {
      width: 50%;
      margin-bottom: 0px !important;
      vertical-align: middle;

      thead {
        background-color: #00b1b1;
        color: #ffffff;

        tr {
          th {
            height: 51px;
            font-size: 1.5rem;
            line-height: 1.5;
            letter-spacing: 0.65px;
          }
        }
      }
      tbody {
        tr {
          td {
            height: 51px;
            font-size: 1.5rem;
            line-height: 1.5;
            letter-spacing: 0.65px;
          }
        }
      }
    }
  }

  .value {
    width: 25%;
    text-align: right;
  }

  .footer {
    text-align: right !important;
  }

  ${media.lessThan('600px')`
    table{
      width: 100% !important;
    }

    .footer{
      font-size: 1.2rem;
    }
  `}
`;
