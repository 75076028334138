import 'bootstrap/dist/css/bootstrap.css';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { Container } from './styles';
import * as text from './text/text';

export default function TableConversao() {
  const { idioma } = useContext(LanguageContext);

  return (
    <Container>
      <div>
        <p>{text.title[idioma]}</p>
      </div>
      <div class="table-responsive-md">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">{text.tableGeneral[idioma]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{text.rechargeFee[idioma]}</td>
              <td class="value">Isento</td>
            </tr>
            <tr>
              <td>{text.bankSlip[idioma]}</td>
              <td class="value">Isento</td>
            </tr>
            <tr>
              <td>{text.lottery[idioma]}</td>
              <td class="value">R$ 7,40</td>
            </tr>
            <tr>
              <td>{text.ATM[idioma]}</td>
              <td class="value">R$ 9,90</td>
            </tr>
            <tr>
              <td>{text.lotWithdrawal[idioma]}</td>
              <td class="value">R$ 7,40</td>
            </tr>
            <tr>
              <td>{text.partner[idioma]}</td>
              <td class="value">R$ 4,90</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-responsive-md">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">{text.tableMember[idioma]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{text.virtualCard[idioma]}</td>
              <td class="value">R$ 15,70+ frete</td>
            </tr>
            <tr>
              <td>{text.contact[idioma]}</td>
              <td class="value">R$ 15,70+ frete</td>
            </tr>
            <tr>
              <td>{text.contactless[idioma]}</td>
              <td class="value">R$19,70 + frete</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive-md">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">{text.tableInternational[idioma]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{text.IOF[idioma]}</td>
              <td class="value">6,38%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive-md">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">{text.tableOther[idioma]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{text.commomCopy[idioma]}</td>
              <td class="value">R$ 24,90</td>
            </tr>
            <tr>
              <td>{text.contactlessCopy[idioma]}</td>
              <td class="value">R$ 29,90</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <p>
          <small>{text.footer[idioma]}</small>
        </p>
      </div>
    </Container>
  );
}
