import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeaderTarifas from "../components/Tarifas"
import Footer from "../components/Footer"
import TableTarifas from "../components/Tarifas/Table"
import Chat from "../components/Chat"

export default function Cotacao() {
  return (
    <Layout>
      <SEO title="Tarifas" />
      <HeaderTarifas />
      <TableTarifas />
      <Footer />
      <Chat />
    </Layout>
  )
}
