export var title = [
  'Conheça as tarifas e limites dos serviços oferecidos pela Hub Fintech',
  'Get to know the fees and limits of the services offered by Hub Fintech',
  'Conozca las tarifas y límites de los servicios ofrecidos por el Hub Fintech',
];

export var footer = [
  'Valores sujeitos a alteração mediante negociação contratual',
  'Values subject to change through contractual negotiation ',
  'Valores sujetos a cambios a través de la negociación contractual',
];

export var tableGeneral = [
  `TARIFAS GERAIS`,
  'GERENAL FEES',
  `TARIFAS GENERALES `,
];

export var rechargeFee = [
  `Tarifa de recarga – Via DOC/TED`,
  `Recharge fee - DOC/TED `,
  `Tasa de recarga - DOC/TED `,
];

export var bankSlip = [
  `Tarifa de recarga via Boleto Bancário`,
  `Bank slip Recharge fee`,
  `Tasa de recarga de Boleto Bancário `,
];

export var lottery = [
  `Tarifa de recarga via Lotérica`,
  `Recharge fee - Lottery`,
  `Tasa de recarga - Lotería `,
];

export var ATM = [
  `Tarifa de saque em caixas eletrônicos (por saque)`,
  `ATM withdrawal fee (per withdrawal)`,
  `Cargo por retiro de cajero automático (por retiro) `,
];

export var lotWithdrawal = [
  `Tarifa de saque em Lotérica`,
  `Lottery withdrawal fee`,
  `Tasa de retiro de lotería`,
];

export var partner = [
  `Tarifa de saque em rede parceira (export varejo)`,
  `Withdrawal fee at partner network (retail)`,
  `Cargo por retiro en la red de socios (venta al por menor)`,
];

export var tableMember = [
  `TARIFA DE ADESÃO (COMPRA DO CARTÃO)`,
  `MEMBERSHIP FEE (CARD PURCHASE)`,
  `CUOTA DE SOCIO (COMPRA DE TARJETA) `,
];

export var virtualCard = [
  `Tarifa de adesão (cartão virtual)`,
  `Membership fee (virtual card)`,
  `Cuota de socio (tarjeta virtual)`,
];

export var contact = [
  `Tarifa de adesão (demais cartões) com contato`,
  `Membership fee (other cards) with contact`,
  `Cuota de socio (otras tarjetas) con contacto`,
];

export var contactless = [
  `Tarifa de adesão (demais cartões) contactless`,
  `Contactless membership fee (other cards)`,
  `Cuota de socio sin contacto (otras tarjetas)`,
];

export var tableInternational = [
  `SERVIÇOS INTERNACIONAIS`,
  `INTERNATIONAL SERVICES`,
  `SERVICIOS INTERNACIONALES`,
];

export var IOF = [
  `IOF – Imposto Nacional - % sobre o valor da transação`,
  `IOF - National Tax - % on transaction value`,
  `IOF - Impuesto Nacional - % sobre el valor de la transacción`,
];

export var tableOther = [`OUTRAS TARIFAS`, `OTHER FEES`, `OTROS HONORARIOS `];

export var commomCopy = [
  `2ª via do cartão comum por perda ou roubo (valor válido a partir de 12/07/2020)`,
  `2nd copy of the common card due to loss or theft (value valid as of 07/12/2020)`,
  `2ª copia de la tarjeta común debido a pérdida o robo (valor válido al 07/12/2020)`,
];

export var contactlessCopy = [
  `2ª via do cartão contactless por perda ou roubo (valor válido a partir de 12/07/2020)`,
  `2nd copy of contactless card in case of loss or theft (value valid as of July 12, 2020)`,
  `2ª copia de la tarjeta sin contacto en caso de pérdida o robo (valor válido a partir del 12 de julio de 2020)`,
];
