import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import flagBra from '../../images/static/bra-flag.png';
import flagEsp from '../../images/static/esp-flag.png';
import flagUsa from '../../images/static/usa-flag.png';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, EN, ES, Header, PT } from './styles';

export default function HeaderTarifas() {
  const { idioma } = useContext(LanguageContext);

  var title = ['Tarifas', 'Rates', 'Tarifas'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          {title[idioma]}
          <hr />
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}

/*
//BANDEIRAS PARA TRADUÇÃO

*/
